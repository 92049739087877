import Config from "./Config.js";
export function connectWebsocket(socketUrl) {
  const connection = new WebSocket(socketUrl, "json.webpubsub.azure.v1");
  return connection;
}
export function makeConnectionUsingWebsocket(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/Getconnectionid",
    data,
    onSuccess,
    onFailure
  );
}
// export function poweryardArrival(request, onSuccess, onFailure) {
// 	httpPost(Config.driverApiServer + '/api/AppRun/GetArrival', request, onSuccess, onFailure);
// }
export function poweryardArrival(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/GetArrivalWFPID",
    request,
    onSuccess,
    onFailure
  );
}

export function postLoginUser(request, onSuccess, onFailure) {
  httpPost(
    Config.loginApiServer + "/authentication/login",
    request,
    onSuccess,
    onFailure
  );
}
export function postLoginUserV2(request, onSuccess, onFailure) {
  httpPost(
    Config.loginApiServer + "/authentication/v2/login",
    request,
    onSuccess,
    onFailure
  );
}
export function checkLogin(onSuccess, onFailure) {
  httpGet(
    Config.loginApiServer + "/authentication/v2/check-login",
    onSuccess,
    onFailure,
    onFailure
  );
  // httpGet(Config.loginApiServer + '/authentication/check-login', onSuccess, onFailure, onFailure);
}
// Start Code by Ali
export function getSecondaryReferenceByRefID(
  secondaryRefNum,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    `/api/primaryReference/v2/secondaryRef/${secondaryRefNum}`,
    onSuccess,
    onFailure
  );
}
export const downloadPyApi = (obj, onSuccess, onFailure) => {
  httpPost(
    Config.primaryApiServer + `/api/locations/v2/apiSetting/search`,
    obj,
    onSuccess,
    onFailure
  );
};
export function savePyApi(obj, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + `/api/locations/v2/apiSetting/save`,
    obj,
    onSuccess,
    onFailure
  );
}
export function disableApiSetting(uuid, onSuccess, onFailure) {
  httpDelete(
    Config.primaryApiServer + `/api/locations/v2/disableApiSetting/${uuid}`,
    onSuccess,
    onFailure,
    onFailure
  );
}
export function clientResponseByTaskUuid(taskUuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + `/api/task/v2/clientResponse/${taskUuid}`,
    onSuccess,
    onFailure
  );
}

export function clientResponseBySpotUuid(spotUuid, locationUuid, priority, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + `/api/task/v2/spotAvailability/${spotUuid}?locationUuid=${locationUuid}&priority=${priority}`,
    onSuccess,
    onFailure
  );
}
export function generateClientSecret(onSuccess, onFailure) {
  httpGet(
    Config.daikinApiServer + `Auth/GenerateClientSecret`,
    onSuccess,
    onFailure,
    onFailure
  );
}
export function getTaskReferenceByRefID(secondaryRefNum, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + `/api/task/v2/${secondaryRefNum}/secondaryRef`,
    onSuccess,
    onFailure
  );
}
export const getAllSecondaryReferenceByRefID = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    if (inputValue.length >= 4) {
      httpGet(
        Config.primaryApiServer +
        `/api/primaryReference/v2/secondaryRef/all/${inputValue}?locationUuid=${locationUuid}`,
        (data) => {
          let listData = data.content.map((obj) => {
            return {
              value: obj.secondaryRef,
              label: obj.secondaryRef,
              uuid: obj.uuid,
              valueSubmit: obj.relatedSecRef,
            };
          });
          // if (inputValue !== "") {
          //     listData = listData.filter(it => it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
          // }
          resolve(listData);
        }
      );
    }
  });

export function savePoweryardCompany(company, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/companies/v2/save-settings",
    company,
    onSuccess,
    onFailure
  );
}
export const promiseAuthorizedLocationSearchForGate = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/locations/v2/gatelocation/" + inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.nickname ? d.nickname : "",
            // enableTaskAssigment: d.enableTaskAssigment,
            // enableTaskInProcess: d.enableTaskInProcess,
            nickname: d.nickname,
            timezone: d.timezone,
            name: d.name,
            id: d.id,
          };
        });
        resolve(data);
      }
    );
  });
};
export function downloadAuthorizedLocationsForGate(
  onSuccess,
  onFailure,
  search
) {
  httpGet(
    Config.primaryApiServer + "/api/locations/v2/gatelocation/",
    onSuccess,
    onFailure
  );
}
// end Code by Ali
//start code by Hamza
export function getMessagesFromPWA(arrivalId, request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer +
    "/api/AppRun/GetPortalArrivalSMS?ArrivalID=" +
    arrivalId,
    request,
    onSuccess,
    onFailure
  );
}
export function postSaveArrival(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/updatearrival",
    request,
    onSuccess,
    onFailure
  );
}
export function getDataByArrivalID(arrivalId, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer +
    "/api/AppRun/getcustomevaluebyarrival?arrivalid=" +
    arrivalId,
    onSuccess,
    onFailure
  );
}
export function getArrivalInfoByArrivald(arrivalId, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer +
    "/api/AppRun/GetArrivalInforByArrivelId?arrivalId=" +
    arrivalId,
    onSuccess,
    onFailure
  );
}


export function getDepartureTrailerInfo(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer +
    "/api/AppRun/GetOldestEmptyTrailer",
    request,
    onSuccess,
    onFailure
  );
}

export function postArrivalTrailerData(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/RequestGateTrailerData",
    request,
    onSuccess,
    onFailure
  );
}
export function postArrivalTrailerDataV2(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/InsertGateTrailerData",
    request,
    onSuccess,
    onFailure
  );
}
export function postArrivalInfoByArrivald(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalInfoByArrivalId",
    request,
    onSuccess,
    onFailure
  );
}
export function postArrivalUpdate(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalInfo",
    request,
    onSuccess,
    onFailure
  );
}
export function cancelArrival(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalCancel",
    request,
    onSuccess,
    onFailure
  );
}
export function downloadGateAttachmentsByArrivalID(arrivalId, controllId, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer +
    "/api/AppRun/downloadGateImagesPDF?ArrivalId=" +
    arrivalId + "&ControllId=" + controllId,
    onSuccess,
    onFailure
  );
}

export function getAttachments(arrivalId, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer +
    "/api/AppRun/GetAttachmentsByArrivalID?arrivalID=" +
    arrivalId,
    onSuccess,
    onFailure
  );
}
export function FetchAllMatchingOnSiteTrailer(locationUuId, trailerNumber, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer +
    "/api/AppRun/FetchAllMatchingOnSiteTrailer?locationUuid=" +
    locationUuId +
    "&trailerNumber=" +
    trailerNumber,
    onSuccess,
    onFailure
  );
}
export function deleteGateAttachment(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/DeleteGateImagesPDF",
    data,
    onSuccess,
    onFailure
  );
}
export function deleteAttachment(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/DeletepdfFromBlob",
    data,
    onSuccess,
    onFailure
  );
}
export function requestGateHistoryToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/ExportExcel",
    data,
    onSuccess,
    onFailure
  );
}
export function poweryardArrivalHistory(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/GetArrivalHistory",
    request,
    onSuccess,
    onFailure
  );
}
export function postMessagePWA(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/SendArrivalSMS2",
    request,
    onSuccess,
    onFailure
  );
}
export function GetPubSubLinkWWS(data, onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer + "/api/AppRun/GetPubSubLinkWWS",
    onSuccess,
    onFailure
  );
}
export function putArrivalStatusChange(data, onSuccess, onFailure) {
  httpPut(
    Config.driverApiServer + "/api/AppRun/ChangeArrivalStatus",
    data,
    onSuccess,
    onFailure
  );
}

export function putCheckInCheckOutStatusChange(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalCheckInCheckOutStatus",
    data,
    onSuccess,
    onFailure
  );
}
// export function updateArrivalDepart(data, onSuccess, onFailure) {
//     httpPost(Config.driverApiServer + '/api/AppRun/UpdateArrivalDepart', data, onSuccess, onFailure);
// }
export function UpdateArrivalCheckOut(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalCheckOut",
    data,
    onSuccess,
    onFailure
  );
}
export function UpdateArrivalCheckIn(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalCheckIn",
    data,
    onSuccess,
    onFailure
  );
}
export function checkFlagForCheckInCheckOut(
  uuid,
  orgUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.driverApiServer +
    `/api/AppRun/flagAgainstUuide4score?uuid=${uuid}&orguuid=${orgUuid}`,
    onSuccess,
    onFailure
  );
}
export function UpdateArrivalComplete(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateArrivalComplete",
    data,
    onSuccess,
    onFailure
  );
}
export function AddEventNotificationSubscriber(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/AddEventNotificationSubscriber",
    data,
    onSuccess,
    onFailure
  );
}
export function getNotification(onSuccess, onFailure) {
  httpGet(
    Config.driverApiServer + "/api/AppRun/GetAllEvents",
    onSuccess,
    onFailure
  );
}
export function GetFreeFieldsByOrganizationUuid(data, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/getOrganizationCustomField",
    data,
    onSuccess,
    onFailure
  );
}
export function postFreeField(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/SaveCustomField",
    request,
    onSuccess,
    onFailure
  );
}
export function postUpdateFreeField(request, onSuccess, onFailure) {
  httpPost(
    Config.driverApiServer + "/api/AppRun/UpdateCustomField",
    request,
    onSuccess,
    onFailure
  );
}
//end code by Hamza
export function logout(onSuccess, onFailure) {
  httpGet(
    Config.loginApiServer + "/authentication/v2/logout",
    onSuccess,
    onFailure
  );
}
export function postRegisterUser(request, onSuccess, onFailure) {
  httpPost(
    Config.loginApiServer + "/authentication/register",
    request,
    onSuccess,
    onFailure
  );
}

export function requestPasswordReset(username, onSuccess, onFailure) {
  let data = {
    username: username,
  };
  httpPost(
    Config.loginApiServer + "/authentication/password-reset",
    data,
    onSuccess,
    onFailure
  );
}
export function requestPasswordResetTA(username, onSuccess, onFailure) {
  let data = {
    username: username,
  };
  httpPost(
    Config.loginApiServer + "/authentication/password-reset-vantix",
    data,
    onSuccess,
    onFailure
  );
}
export function checkPasswordReset(data, onSuccess, onFailure) {
  httpPost(
    Config.loginApiServer + "/authentication/password-reset-login",
    data,
    onSuccess,
    onFailure
  );
}

export function checkVersion(hash, onsuccess, onerror) {
  httpGet(
    Config.loginApiServer + "/authentication/new-version-check/" + hash,
    onsuccess,
    onerror,
    null
  );
}

export function performPasswordReset(data, onSuccess, onFailure) {
  httpPost(
    Config.loginApiServer + "/api/users/v2/reset-password",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadCurrentStep(uuid, onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/ebw/" + uuid, onSuccess, onFailure);
}

export function downloadCurrentDispatchStep(uuid, onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/" + uuid + "/ebw", onSuccess, onFailure);
}

export function sendResponseToStep(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/ebw/response",
    data,
    onSuccess,
    onFailure
  );
}

export function sendDispatchEvent(uuid, data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/" + uuid + "/ebw",
    data,
    onSuccess,
    onFailure
  );
}

export function sendGps(data, onSuccess, onFailure) {
  httpPost(Config.primaryApiServer + "/ebw/gps", data, onSuccess, onFailure);
}

export function sendDispatchGps(uuid, data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/" + uuid + "/ebw/gps",
    data,
    onSuccess,
    onFailure
  );
}

export function postDispatch(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch",
    request,
    onSuccess,
    onFailure
  );
}

export function startDispatch(uuid, request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch/" + uuid + "/start",
    request,
    onSuccess,
    onFailure
  );
}

export function archiveDispatch(uuid, request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch/" + uuid + "/archive",
    request,
    onSuccess,
    onFailure
  );
}

export function downloadDispatchsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadDispatchPlan(uuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/dispatch/" + uuid,
    onSuccess,
    onFailure
  );
}

export function downloadEZTrackDevicesForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-device/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadPingAssetsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-asset/v2/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadCancelReasonCodes(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/cancel-reason-code/v2/search",
    data,
    onSuccess,
    onFailure
  );
}

export function getDefaultCancelReasonCode(onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/cancel-reason-code/v2/default",
    onSuccess,
    onFailure
  );
}

export function postCancelReasonCode(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/cancel-reason-code/v2/save",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadTaskHistory(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/searchTaskHistory",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadYardHistory(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/searchYardHistory",
    data,
    onSuccess,
    onFailure
  );
}
export function requestTaskHistoryToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/history/export",
    data,
    onSuccess,
    onFailure
  );
}

export function requestArrivalLogToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/arrivalLog/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}

export function requestOnSiteYardToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/searchOnSite/export",
    data,
    onSuccess,
    onFailure
  );
}
export function requestYardHistoryToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/yardhistory/export",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadTasks(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/search",
    data,
    onSuccess,
    onFailure
  );
}
export function searchAseetDwell(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/arrivalLog/v2/search",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadNonNullMoveToTasks(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/searchOnSite",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadNonNullMoveToTasksV2(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/searchOnSite",
    data,
    onSuccess,
    onFailure
  );
}
export function setupAddAsset(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/setupAddAsset",
    data,
    onSuccess,
    onFailure
  );
}
export function setupDepartAsset(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/setupDepartAsset",
    data,
    onSuccess,
    onFailure
  );
}
export function postDepartAsset(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/depart",
    data,
    onSuccess,
    onFailure
  );
}
export function saveYardCheckStatusToAllTasks(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/saveAllTask",
    data,
    onSuccess,
    onFailure
  );
}
export function putTaskStart(obj, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/start",
    obj,
    onSuccess,
    onFailure
  );
}
export function putTaskStartInList(obj, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/start",
    obj,
    onSuccess,
    onFailure
  );
}
export function putTaskEnd(obj, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/end",
    obj,
    onSuccess,
    onFailure
  );
}
export function putTaskEndInList(obj, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/end",
    obj,
    onSuccess,
    onFailure
  );
}

export function putTaskAssign(obj, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/accept",
    obj,
    onSuccess,
    onFailure
  );
}

export function assignTask(data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/assignTask",
    data,
    onSuccess,
    onFailure
  );
}
export function putTaskRemoveAssignee(
  uuid,
  taskVersion,
  data,
  onSuccess,
  onFailure
) {
  let obj = {
    taskUuid: uuid,
    taskVersion: taskVersion,
    coordinates: data,
  };
  httpPut(
    Config.primaryApiServer + "/api/task/v2/remove-assignee",
    obj,
    onSuccess,
    onFailure
  );
}

export function putTaskCancel(data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/v2/cancel",
    data,
    onSuccess,
    onFailure
  );
}
export function putTaskAsignee(data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/task/assigntaskasignee/",
    data,
    onSuccess,
    onFailure
  );
}

export function saveNewPingAsset(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-asset/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export const promiseEZTrackBaseEquipmentByPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/eztrack-equipment/v2/types/" + inputValue,
      (data) => {
        let listData = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.equipmentType,
            ...d,
          };
        });
        resolve(listData);
      }
    );
  });
export const promiseEZTrackEquipmentFuel = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/eztrack-equipment/v2/fuel-levels/" + (inputValue ? "?prefix=" + inputValue : ""),
      (data) => {
        let listData = data.content.map((d) => {
          return {
            uuid: d.uuid,
            label: d.fuelLevel,
            ...d,
          };
        });
        resolve(listData);
      }
    );
  });
export const promiseDefaultAsseTType = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    if (locationUuid) {
      httpGet(Config.primaryApiServer + "/api/locations/", (data) => {
        let listData = data;
        let assetObj = listData.find((it) => it.uuid === locationUuid);
        if (assetObj.equipments.length !== 0) {
          listData = assetObj.equipments.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          resolve(listData);
        } else {
          httpGet(
            Config.primaryApiServer +
            "/api/eztrack-equipment/v2/search/" +
            inputValue,
            (data) => {
              let listData = data.content.map((d) => {
                return {
                  value: d.uuid,
                  label: d.name,
                  ...d,
                };
              });
              resolve(listData);
            }
          );
        }
      });
    } else {
      httpGet(
        Config.primaryApiServer +
        "/api/eztrack-equipment/v2/search/" +
        inputValue,
        (data) => {
          let listData = data.content.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          resolve(listData);
        }
      );
    }
  });
export const promiseEZTrackEquipmentTypeByPrefix =
  (locationUuid) => (inputValue) =>
    new Promise((resolve) => {
      httpGet(
        Config.primaryApiServer +
        "/api/locations/v2/" +
        locationUuid +
        "/asset-types" +
        (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
        (data) => {
          let listData = data.content.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          // if (inputValue !== "") {
          // 	listData = listData.filter(it => it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
          // }
          resolve(listData);
        }
      );
    });

export const promiseTaskTypeByLocationUUid = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/" +
      locationUuid +
      "/task-types" +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        let listData = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        if (inputValue !== "") {
          listData = listData.filter((it) =>
            it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
          );
        }
        resolve(listData);
      }
    );
  });
export const promiseSpotTypeByLocationUUidAzure = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.driverApiServer +
      "/api/SpotByLocationUuId?locationUuId=" +
      locationUuid,
      (data) => {
        if (data.statusCode == "200") {
          let listData = data.data.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          if (inputValue !== "") {
            listData = listData.filter((it) =>
              it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
            );
          }
          resolve(listData);
        } else {
          resolve([]);
        }

      }
    );
  });
export const promiseSpotTypeByLocationUUid = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/" +
      locationUuid +
      "/spot-types" +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        let listData = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        if (inputValue !== "") {
          listData = listData.filter((it) =>
            it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
          );
        }
        resolve(listData);
      }
    );
  });
export const promiseCancelReasonByLocationUUid =
  (locationUuid) => (inputValue) =>
    new Promise((resolve) => {
      httpGet(
        Config.primaryApiServer +
        "/api/locations/v2/" +
        locationUuid +
        "/cancel-reason" +
        (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
        (data) => {
          let listData = data.content.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          if (inputValue !== "") {
            listData = listData.filter((it) =>
              it.name
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase())
            );
          }
          resolve(listData);
        }
      );
    });
export const promiseAssetStatusesByLocationUUid =
  (locationUuid) => (inputValue) =>
    new Promise((resolve) => {
      httpGet(
        Config.primaryApiServer +
        "/api/locations/v2/" +
        locationUuid +
        "/asset-statuses" +
        (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
        (data) => {
          let listData = data.content.map((d) => {
            return {
              value: d.uuid,
              label: d.name,
              ...d,
            };
          });
          if (inputValue !== "") {
            listData = listData.filter((it) =>
              it.name
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase())
            );
          }
          resolve(listData);
        }
      );
    });

export function getDefaultTaskTypeByLocation(
  locationUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/locations/v2/" +
    locationUuid +
    "/task-types",
    onSuccess,
    onFailure
  );
}
export function getTaskTypeGate(locationUuid, orgUuid, onSuccess, onFailure) {
  return new Promise((resolve, reject) => {
    httpGet(
      Config.driverApiServer + "/api/AppRun/GetTaskType?orgUUID=" + orgUuid + "&locUUID=" + locationUuid,
      (response) => {
        if (response.statusCode === "200" && response.data) {
          const arrivalOptions = [];
          const departureOptions = [];

          response.data.forEach((taskType) => {
            const option = {
              value: taskType.UUID,
              label: taskType.Name,
            };

            if (taskType.ForArrival) {
              arrivalOptions.push(option);
            }
            if (taskType.ForDepart) {
              departureOptions.push(option);
            }
          });

          resolve({ arrivalOptions, departureOptions });
          if (onSuccess) onSuccess({ arrivalOptions, departureOptions });
        } else {
          reject("Unexpected response format");
          if (onFailure) onFailure("Unexpected response format");
        }
      },
      (error) => {
        reject(error);
        if (onFailure) onFailure(error);
      }
    );
  });
}


export function getFieldSettingsForScreen(data, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer +
    "/api/screen-config/v2/" +
    data.locationUuid +
    "/" +
    data.screenCode,
    onSuccess,
    onFailure
  );
}
export function getDefaultAssetStatusByLocation(
  locationUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/locations/v2/" +
    locationUuid +
    "/asset-statuses",
    onSuccess,
    onFailure
  );
}
export function getRolesByLocation(
  locationUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/priority-config/v2/" +
    locationUuid + "/",
    onSuccess,
    onFailure
  );
}
export function getMoveTaskStatueByTaskuuid(
  taskUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/task/v2/movedTask/" +
    taskUuid + "/",
    onSuccess,
    onFailure
  );
}

// export const  = (locationUuid) => inputValue =>
// new Promise(resolve => {
//     httpGet(Config.primaryApiServer + '/api/locations/v2/'+locationUuid+'/asset-statuses' , (data) => {
//         let listData = data.content.map((d) => {
//             return {
//                 value: d.uuid,
//                 label: d.name,
//                 ...d
//             }
//         });
//          if (inputValue !== "") {
//             listData = listData.filter(it => it.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
//         }
//         resolve(listData)
//     })
// });
export const promiseEZTrackEquipmentByPrefix = (inputValue) =>
  promiseSearchByPrefixForEquipmentSelectBox(
    "/api/eztrack-equipment/v2/search/",
    inputValue
  );

export const promiseTaskTypeByPrefix = (inputValue) =>
  promiseSearchByPrefixForSelectBox("/api/task-type/search/", inputValue);

export const promiseAssetByPrefix = (carrierUuid) => (inputValue) => {
  if (carrierUuid == "") {
    return new Promise((resolve) => resolve([]));
  }
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/eztrack-asset/v2/carrier/" +
      carrierUuid +
      "/search/" +
      (inputValue ? "?assetPrefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        let arr = data.content;
        let listData = arr.map((d) => {
          d.value = d.uuid;
          d.label = d.assetId;

          return d;
        });
        resolve(listData);
      }
    );
  });
};

export const promiseAssetStatusByPrefix = (inputValue) =>
  promiseSearchByPrefixForSelectBox("/api/asset-status/v2/search/", inputValue);

export const promiseSpotForLocationByZonePrefixOrSpotPrefix =
  (locationUuid) => (inputValue) => {
    if (locationUuid == "") {
      return new Promise((resolve) => resolve([]));
    }
    return new Promise((resolve) => {
      httpGet(
        Config.primaryApiServer +
        "/api/spot/v2/location/" +
        locationUuid +
        "/spot/" +
        (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
        (data) => {
          let listData = data.content.map((d) => {
            d.value = d.zone + (d.name ? " - " + d.name : "");
            d.label = d.zone + (d.name ? " - " + d.name : "");
            return d;
          });
          resolve(listData);
        }
      );
    });
  };
export function promiseUserByLocation(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/users/v2/search",
    request,
    onSuccess,
    onFailure
  );
}

// export const getUsersByLocation = (uuid, inputValue) =>{

//      new Promise(resolve => {
//            httpGet(Config.primaryApiServer + '/api/users/v2/location/'+uuid+(inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ''), (data) => {
//                data.content.map((d) => {
//                    d.value = d.uuid;
//                    d.label = d.name;
//                    d.uuid = d.uuid;
//                    return d
//                });
//                resolve(data)
//            })
//        });
// }
export const getUsersByLocation = (uuid) => (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/users/v2/jockey/location/" +
      uuid +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.firstname + " " + d.lastname,
            uuid: d.uuid,
            ...d,
          };
        });
        // if (inputValue !== "") {
        //     data = data.filter(it => it.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
        // }
        resolve(data);
      }
    );
  });
};
export const promiseAssetStatusesByPref = (inputValue) => {
  return new Promise((resolve) => {
    // httpGet(Config.primaryApiServer + '/api/asset-status/v2/search/' + (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ''), (data) => {
    httpGet(
      Config.primaryApiServer + "/api/asset-status/v2/search/" + inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        // if (inputValue !== "") {
        //     data = data.filter(it => it.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
        // }
        resolve(data);
      }
    );
  });
};
export const promiseUserByLocationEdit = (locationUuid, user) => (inputValue) =>
  new Promise((resolve) => {
    let obj = {
      page: 0,
      pageSize: 50,
      sorted: [],
      filtered: [],
    };
    if (user.roles[0].role !== "ROLE_POWERYARD_JOCKEY") {
      httpPost(
        Config.primaryApiServer + "/api/users/v2/search",
        obj,
        (data) => {
          let userArr = data.content.results;
          let listData = [];
          userArr = userArr.filter((it) => it.enabled == true);

          userArr.forEach((element) => {
            element.assignee = element.firstname + " " + element.lastname;
            element.label = element.firstname + " " + element.lastname;

            if (
              inputValue === "" ||
              inputValue === null ||
              inputValue === undefined
            ) {
              if (element.locations.length > 0) {
                let userLocation = element.locations.filter(
                  (it) => it.uuid === locationUuid
                );
                if (userLocation.length > 0) {
                  listData.push(element);
                }
              }
            } else {
              if (element.locations.length === 0) {
                listData.push(element);
              }
              if (element.locations.length > 0) {
                let userLocation = element.locations.filter(
                  (it) => it.uuid === locationUuid
                );
                if (userLocation.length > 0) {
                  listData.push(element);
                }
              }
            }
          });
          if (inputValue !== "") {
            listData = listData.filter((it) =>
              it.assignee
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase())
            );
          }
          resolve(listData);
        }
      );
    } else {
      let searchString = inputValue;
      let userArr = [];
      let listData = [];
      userArr.push(user);
      userArr.forEach((element) => {
        element.assignee = element.firstname + " " + element.lastname;
        element.label = element.firstname + " " + element.lastname;
        if (element.locations.length === 0) {
          if (
            searchString !== "" &&
            element.assignee
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())
          ) {
            listData.push(element);
          } else if (searchString === "") {
            listData.push(element);
          } else {
          }
        } else {
          let userTemp = element.locations.filter(
            (it) => it.uuid === locationUuid
          );
          if (userTemp.length > 0) {
            if (
              searchString !== "" &&
              element.assignee
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase())
            ) {
              listData.push(element);
            } else if (searchString === "") {
              listData.push(element);
            } else {
            }
          }
        }
      });

      resolve(listData);
    }
  });

export const promiseSearchByPrefixForSelectBox = (searchPath, inputValue) =>
  new Promise((resolve) => {
    httpGet(Config.primaryApiServer + searchPath + inputValue, (data) => {
      data.content.map((d) => {
        d.value = d.name;
        d.label = d.name;
        return d;
      });
      resolve(data);
    });
  });
export const promiseSearchByPrefixForEquipmentSelectBox = (
  searchPath,
  inputValue
) =>
  new Promise((resolve) => {
    httpGet(Config.primaryApiServer + searchPath + inputValue, (data) => {
      let listData = data.content.map((d) => {
        d.value = d.name;
        d.label = d.name;
        return d;
      });
      resolve(listData);
    });
  });

export function downloadEZTrackTrackingFrequencies(data, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/eztrack-tracking-frequency/search/" + data,
    onSuccess,
    onFailure
  );
}
export function downloadEZTrackEquipmentForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-equipment/v2/search",
    data,
    onSuccess,
    onFailure
  );
}

export function saveNewEZTrackEquipment(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-equipment/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export function getDefaultEZTrackEquipment(uuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/eztrack-equipment/v2/" + uuid + "/default",
    onSuccess,
    onFailure
  );
}

export function saveAssetStatus(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/asset-status/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export function downloadAssetStatusForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/asset-status/v2/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadSpotsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/spot/v2/search",
    data,
    onSuccess,
    onFailure
  );

}
export function downloadNoticeForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/notification/v2/search",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadFieldValidityForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/screen-config/v2/searchAll",
    data,
    onSuccess,
    onFailure
  );
}
export function requestSpotsToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/spot/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}
export function requestNoticeToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/notification/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}

export function saveSpot(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/spot/v2/save",
    request,
    onSuccess,
    onFailure
  );
}
export function saveNotice(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/notification/v2/",
    request,
    onSuccess,
    onFailure
  );
}
export function saveFieldValidity(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/screen-config/v2/",
    request,
    onSuccess,
    onFailure
  );
}
export function importSpotFile(file, onsuccess, onerror, onautherror = null) {
  postFile(
    Config.primaryApiServer + "/api/spot/v2/import",
    file,
    onsuccess,
    onerror,
    new FormData(),
    onautherror
  );
}

export function saveTaskType(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task-type/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export function downloadTaskTypesForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task-type/v2/search",
    data,
    onSuccess,
    onFailure
  );
}

export function saveSpotType(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/spot-type/v2/save",
    request,
    onSuccess,
    onFailure
  );
}
export function savePriority(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/priority-config/v2/save",
    request,
    onSuccess,
    onFailure
  );
}
export function downloadRolesForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/priority-config/v2/search",
    data,
    onSuccess,
    onFailure
  );
}
export function downloadSpotTypesForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/spot-type/v2/search",
    data,
    onSuccess,
    onFailure
  );
}
export function getSpotNamesForDropdown(onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/spot-type/v2/",
    {
      page: 0,
      pageSize: 1000,
      sorted: [{ id: "name", desc: true }],
      filtered: [],
    },
    onSuccess,
    onFailure
  );
}
export const promiseAllSpotTypes = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/spot-type/v2/" + inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            uuid: d.uuid,
            name: d.name,
            defaultValue: d.def,
          };
        });
        resolve(data);
      }
    );
  });
};
export const promiseAllTaskTypes = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/task-type/v2/search/" + inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            uuid: d.uuid,
            name: d.name,
          };
        });

        resolve(data);
      }
    );
  });
};
export function downloadDispatchAssetsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch-assets/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadDispatchAssessorialsForTable(
  data,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer + "/api/dispatch-assessorials/search",
    data,
    onSuccess,
    onFailure
  );
}

export function postArrival(request, onSuccess, onFailure) {
  console.log("Posting arrival", request);
  httpPost(
    Config.primaryApiServer + "/api/gate-event/",
    request,
    onSuccess,
    onFailure
  );
}

export function postDeparture(request, onSuccess, onFailure) {
  console.log("Posting departure", request);
  httpPost(
    Config.primaryApiServer + "/api/gate-event/",
    request,
    onSuccess,
    onFailure
  );
}

export function downloadAssets(type, onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/api/" + type + "/", onSuccess, onFailure);
}

export function getNotes(uuid, onSuccess, onFailure) {
  if (!uuid) {
    return {};
  }
  httpGet(
    Config.primaryApiServer + "/api/assets/" + uuid + "/notes",
    onSuccess,
    onFailure
  );
}
export function getNotice(onSuccess, onFailure) {

  httpGet(
    Config.primaryApiServer + "/api/notification/v2/",
    onSuccess,
    onFailure
  );
}
export function saveNote(uuid, request, onSuccess, onFailure) {
  if (!uuid) {
    return {};
  }
  httpPost(
    Config.primaryApiServer + "/api/assets/" + uuid + "/notes",
    request,
    onSuccess,
    onFailure
  );
}

export function sendDriverSms(uuid, data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/voice-message/" + uuid + "/driver",
    data,
    onSuccess,
    onFailure
  );
}

export function sendDispatchSms(uuid, data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/dispatch/" + uuid + "/send-sms",
    data,
    onSuccess,
    onFailure
  );
}

export function sendDispatchAll(data, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/dispatch/remind-all",
    data,
    onSuccess,
    onFailure
  );
}

export function getRemindAll(onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/dispatch/remind-all",
    onSuccess,
    onFailure
  );
}

export function downloadAssetsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/assets/onsite-table/",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadVisitsForTable(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/asset-visit/search",
    data,
    onSuccess,
    onFailure
  );
}

export function requestVisitsToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/asset-visit/export",
    data,
    onSuccess,
    onFailure
  );
}

export function requestLocationsToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/locations/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}

export function requestAssetsHistoryToExcel(
  timezone,
  data,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/dispatch-assessorials/export?timezone=" +
    timezone,
    data,
    onSuccess,
    onFailure
  );
}

export function requestVisitsToShipmentsExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/shipments/export",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadCarrierList(onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/api/carriers/", onSuccess, onFailure);
}
export const promiseCarrierListDrop = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/carriers/v2/search" +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            uuid: d.uuid,
            name: d.name,
          };
        });
        // if(inputValue !== ""){

        //     data = data.filter(it => it.label.toLowerCase().includes(inputValue.toLowerCase()));
        // }

        resolve(data);
      }
    );
  });
};
export function promiseCarrierList() {
  return new Promise((resolve) => {
    httpGet(Config.primaryApiServer + "/api/carriers/", (data) => {
      data = data.map((d) => {
        return {
          value: d.uuid,
          label: d.name,
          ...d,
        };
      });

      resolve(data);
    });
  });
}
export function exportAssetsToExcel(
  searchParams,
  filename,
  onSuccess,
  onFailure
) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/eztrack-asset/v2/export",
    searchParams,
    filename,
    onSuccess,
    onFailure
  );
}
export function exportCarriersToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/carriers/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}

export function postCarrier(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/carriers/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export function searchCarriers(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/carriers/v2/search",
    request,
    onSuccess,
    onFailure
  );
}

export function searchShipments(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/shipments/search",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadShipmentStatuses(onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/shipment-status/",
    onSuccess,
    onFailure
  );
}

export function searchNextShipments(
  companyuuid,
  timeZone,
  dispatchUUID,
  data,
  onSuccess,
  onFailure
) {
  timeZone = timeZone.replace("/", "-");
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyuuid +
    "/next-shipment-profile/" +
    timeZone +
    "/" +
    dispatchUUID +
    "/" +
    true,
    data,
    onSuccess,
    onFailure
  );
}
export function searchPreviousShipments(
  companyuuid,
  timeZone,
  dispatchUUID,
  data,
  onSuccess,
  onFailure
) {
  timeZone = timeZone.replace("/", "-");
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyuuid +
    "/next-shipment-profile/" +
    timeZone +
    "/" +
    dispatchUUID +
    "/" +
    false,
    data,
    onSuccess,
    onFailure
  );
}
export function searchShipmentsByAssetId(assetId, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/shipments/search?assetId=" + assetId,
    onSuccess,
    onFailure
  );
}

export function updateShipmentComment(uuid, data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/gate-event/" + uuid + "/comment",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadLocationList(onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/api/locations/", onSuccess, onFailure);
}

export function downloadAuthorizedLocations(onSuccess, onFailure, search) {
  httpGet(
    Config.primaryApiServer + "/api/locations/v2/search/authorized/",
    onSuccess,
    onFailure
  );
}

export function downloadWorkflows(onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/api/workflows/", onSuccess, onFailure);
}

export function downloadWorkflow(uuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/workflows/" + uuid,
    onSuccess,
    onFailure
  );
}
export function saveWorkflowCustomizations(
  workflowUuid,
  customizations,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/workflows/" +
    workflowUuid +
    "/customizations",
    customizations,
    onSuccess,
    onFailure
  );
}

export function postLocation(request, companyPoweryard, onSuccess, onFailure) {
  if (companyPoweryard === false) {
    request.enableTaskInProcess = false;
  }
  httpPost(
    Config.primaryApiServer + "/api/locations/v2/save",
    request,
    onSuccess,
    onFailure
  );
}

export function searchLocations(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/locations/v2/search",
    request,
    onSuccess,
    onFailure
  );
}

export function importLocationFile(
  file,
  onsuccess,
  onerror,
  onautherror = null
) {
  postFile(
    Config.primaryApiServer + "/api/locations/v2/import",
    file,
    onsuccess,
    onerror,
    new FormData(),
    onautherror
  );
}

export function importCarrierFile(
  file,
  onsuccess,
  onerror,
  onautherror = null
) {
  postFile(
    Config.primaryApiServer + "/api/carriers/v2/import",
    file,
    onsuccess,
    onerror,
    new FormData(),
    onautherror
  );
}

export function downloadUserList(onSuccess, onFailure) {
  httpGet(Config.primaryApiServer + "/api/users/", onSuccess, onFailure);
}

export function postUser(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/users/v2/save",
    request,
    onSuccess,
    onFailure
  );
}
export function yardCheck(request, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/users/v2/useryardcheck",
    request,
    onSuccess,
    onFailure
  );
}
export function exportUsersToExcel(data, filename, onSuccess, onFailure) {
  httpPostDownloadFile(
    Config.primaryApiServer + "/api/users/v2/export",
    data,
    filename,
    onSuccess,
    onFailure
  );
}

export function postUserDefaultLocation(locationUuid, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/users/v2/defaultLocation",
    {
      uuid: locationUuid,
    },
    onSuccess,
    onFailure
  );
}
export function postUserDefaultShift(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/users/v2/saveShift",
    data,
    onSuccess,
    onFailure
  );
}
export function getPyApi(obj, onSuccess, onFailure) {
  obj = JSON.stringify(obj);
  let request = JSON.parse(obj);
  if (request.filtered.length > 0) {
    let a = request.filtered.findIndex((it) => it.id === "enabled");
    if (a > -1) {
      if (
        request.filtered[a].value.toString() !== "true" &&
        request.filtered[a].value.toString() !== "false"
      ) {
        request.filtered.splice(a, 1);
      }
    }
  }
  httpPost(
    Config.primaryApiServer + "/api/users/v2/search",
    request,
    onSuccess,
    onFailure
  );
}

export function searchUsers(obj, onSuccess, onFailure) {
  obj = JSON.stringify(obj);
  let request = JSON.parse(obj);
  if (request.filtered.length > 0) {
    let a = request.filtered.findIndex((it) => it.id === "enabled");
    if (a > -1) {
      if (
        request.filtered[a].value.toString() !== "true" &&
        request.filtered[a].value.toString() !== "false"
      ) {
        request.filtered.splice(a, 1);
      }
    }
  }
  httpPost(
    Config.primaryApiServer + "/api/users/v2/search",
    request,
    onSuccess,
    onFailure
  );
}

export function importUserFile(file, onsuccess, onerror, onautherror = null) {
  postFile(
    Config.primaryApiServer + "/api/users/v2/import",
    file,
    onsuccess,
    onerror,
    new FormData(),
    onautherror
  );
}

export function welcomeEmail(uuid, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/users/v2/" + uuid + "/welcome-email",
    null,
    onSuccess,
    onFailure
  );
}

export function downloadDepartureGateEventFromAsset(
  locationUuid,
  assetUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/gate-event/departure-from-asset/" +
    locationUuid +
    "/" +
    assetUuid,
    onSuccess,
    onFailure
  );
}

export function downloadCountries(onSuccess, onFailure) {
  httpGet("/json/countries.json", onSuccess, onFailure);
}

export function downloadStates(forCountry, onSuccess, onFailure) {
  switch (forCountry) {
    case "United States":
      return httpGet("/json/us-states.json", onSuccess, onFailure);
    case "Canada":
      return httpGet("/json/ca-provinces.json", onSuccess, onFailure);
    case "Mexico":
      return httpGet("/json/mx-states.json", onSuccess, onFailure);
    default:
      return onSuccess({
        states: [],
      });
  }
}
//ali
export function setCompanyAdministrationUuid(uuid, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer +
    "/api/users/v2/change-company-administration/" +
    uuid,
    null,
    onSuccess,
    onFailure
  );
}

export function saveCompany(company, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/companies/v2/save",
    company,
    onSuccess,
    onFailure
  );
}

export function updateCompany(company, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/companies/v2/save",
    company,
    onSuccess,
    onFailure
  );
}

export function updateNotificationSettings(
  companyUuid,
  settings,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/event-notification-settings",
    settings,
    onSuccess,
    onFailure
  );
}

export function updateWorkflowSettings(
  companyUuid,
  settings,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/workflow-settings",
    settings,
    onSuccess,
    onFailure
  );
}

export function newReferenceType(companyUuid, type, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer +
    "/api/companies/v2/" +
    companyUuid +
    "/reference-types",
    type,
    onSuccess,
    onFailure
  );
}

export function updateReferenceTypes(companyUuid, types, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/v2/" +
    companyUuid +
    "/reference-types",
    types,
    onSuccess,
    onFailure
  );
}

export function getReferenceTypes(companyUuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer +
    "/api/companies/v2/" +
    companyUuid +
    "/reference-types",
    onSuccess,
    onFailure
  );
}

export function saveTrackAssuredIntegrationSettings(
  companyUuid,
  settings,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/track-assured-integration-settings",
    settings,
    onSuccess,
    onFailure
  );
}

export function saveDispatchSettings(
  companyUuid,
  settings,
  onSuccess,
  onFailure
) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/dispatch-settings",
    settings,
    onSuccess,
    onFailure
  );
}

export function generateApiSecret(companyUuid, settings, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/generate-api-secret",
    settings,
    onSuccess,
    onFailure
  );
}

export function saveTask(task, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/save",
    task,
    onSuccess,
    onFailure
  );
}

export function downloadTaskSpotHistory(searchParams, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/task/v2/history",
    searchParams,
    onSuccess,
    onFailure
  );
}

export function downloadTaskByUuid(uuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/task/v2/" + uuid,
    onSuccess,
    onFailure
  );
}

export function downloadEZTrackAssetProfile(uuid, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/eztrack-asset/" + uuid,
    onSuccess,
    onFailure
  );
}

export function importEZTrackAssetFile(
  file,
  onsuccess,
  onerror,
  onautherror = null
) {
  postFile(
    Config.primaryApiServer + "/api/eztrack-asset/v2/import",
    file,
    onsuccess,
    onerror,
    new FormData(),
    onautherror
  );
}

export function departAsset(uuid, departureDate, onSuccess, onFailure) {
  httpPut(
    Config.primaryApiServer + "/api/assets/" + uuid + "/depart",
    departureDate,
    onSuccess,
    onFailure
  );
}

export function downloadEZTrackAssetEvents(searchData, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api-v1/event/search",
    searchData,
    onSuccess,
    onFailure
  );
}

export function saveShipmentStatusEvent(uuid, data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/shipments/" + uuid + "/status-event",
    data,
    onSuccess,
    onFailure
  );
}

export function updateShipmentStatusEvent(uuid, date, onSuccess, onFailure) {
  httpPatch(
    Config.primaryApiServer + "/api/shipment-status-event/" + uuid,
    date,
    onSuccess,
    onFailure
  );
}

export function requestEventsToExcel(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api-v1/event/export",
    data,
    onSuccess,
    onFailure
  );
}

export function requestTrackExcelToAssets(data, onSuccess, onFailure) {
  httpPost(
    Config.primaryApiServer + "/api/eztrack-device/exportEZTrackAssets",
    data,
    onSuccess,
    onFailure
  );
}

export function downloadShipmentProfile(
  uuid,
  companyUuid,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/shipment-profile/" +
    uuid,
    onSuccess,
    onFailure
  );
}
export function downloadShipmentTAProfile(
  uuid,
  companyUuid,
  trackObj,
  onSuccess,
  onFailure
) {
  trackObj.timezone = trackObj.timezone.replace("/", "-");
  httpGet(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/shipment-profile-v3/" +
    uuid +
    "/" +
    trackObj.timezone +
    "/" +
    trackObj.trackType +
    "/" +
    trackObj.trackID,
    onSuccess,
    onFailure
  );
}
export function trackShipmentsUsingTrackingID(
  uuid,
  timeZone,
  companyUuid,
  data,
  onSuccess,
  onFailure
) {
  timeZone = timeZone.replace("/", "-");
  httpPost(
    Config.primaryApiServer +
    "/api/companies/" +
    companyUuid +
    "/track-shipment-profile/" +
    timeZone +
    "/" +
    uuid,
    data,
    onSuccess,
    onFailure
  );
}
export function trackShipmentsUsingTrackingIDAnonymously(
  uuid,
  timezone,
  data,
  onSuccess,
  onFailure
) {
  timezone = timezone.replace("/", "-");
  httpPost(
    Config.primaryApiServer +
    "/authentication/track-shipment-profile-anonymous/" +
    timezone +
    "/" +
    uuid,
    data,
    onSuccess,
    onFailure
  );
}
export function getZipInformation(postal, onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/api/postal/v2/?zip=" + postal,
    onSuccess,
    onFailure
  );
}

export const promiseAddressByPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/address/v2/search/" + inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.incompleteAddress;
          d.label = d.fullLocation;
          return d;
        });
        resolve(data);
      }
    );
  });

export function getPhysicalAddressInformation(
  street,
  city,
  state,
  onSuccess,
  onFailure
) {
  httpGet(
    Config.primaryApiServer +
    "/api/address/v2?search=street=" +
    street +
    "&city=" +
    city +
    "&state=" +
    state,
    onSuccess,
    onFailure
  );
}

export function downloadCountryCodeData(onSuccess, onFailure) {
  httpGet(
    Config.primaryApiServer + "/phonenumbers/country-codes",
    onSuccess,
    onFailure
  );
}

export function postDemo(request, onSuccess, onFailure) {
  httpPost(Config.primaryApiServer + "/demo", request, onSuccess, onFailure);
}

export const promiseCarriersByPrefixIncludingSelf = (company) => (inputValue) =>
  new Promise((resolve) => {
    let filters = [];
    if (inputValue) {
      filters.push({
        id: "name",
        value: inputValue,
      });
    }
    let searchRequest = {
      filtered: filters,
      sorted: [
        {
          id: "self",
          desc: true,
        },
        {
          id: "name",
          desc: false,
        },
      ],
      page: 0,
      pageSize: 30,
    };
    httpPost(
      Config.primaryApiServer + "/api/carriers/v2/search",
      searchRequest,
      (data) => {
        let listData = data.content.results.map((d) => {
          d.value = d.name;
          d.label = d.name;
          return d;
        });
        resolve(listData);
      }
    );
  });

export const promiseCarriersByPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/carriers/v2/search/" + inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.name;
          d.label = d.name;
          return d;
        });
        resolve(data);
      }
    );
  });
export const carriersByLocationUUid = (locationUuid) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/carriers/v2/location/" +
      locationUuid +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        let listData = data.content.map((d) => {
          return {
            value: d.name,
            label: d.name,
            ...d,
          };
        });
        // if (inputValue) {
        //     listData = listData.filter(it => it.name.toLowerCase().includes(inputValue.toLowerCase()));
        // }
        resolve(listData);
      }
    );
    // if (locationUuid) {

    //     httpGet(Config.primaryApiServer + '/api/locations/', (data) => {
    //         let listData = data;
    //         let locObj = listData.find(it => it.uuid === locationUuid);
    //         if (locObj.carrier.length !== 0) {
    //             listData = locObj.carrier.map((d) => {
    //                 return {
    //                     value: d.name,
    //                     label: d.name,
    //                     ...d
    //                 }
    //             });
    //             if (inputValue) {
    //                 listData = listData.filter(it => it.name.toLowerCase().includes(inputValue.toLowerCase()));
    //             }
    //             resolve(listData)
    //         }
    //          else {
    //             httpGet(Config.primaryApiServer + '/api/carriers/v2/search/' + inputValue, (data) => {
    //                 let listData = data.content.map((d) => {
    //                     return {
    //                         value: d.name,
    //                         label: d.name,
    //                         ...d
    //                     }
    //                 });
    //                 if (inputValue) {
    //                     listData = listData.filter(it => it.name.toLowerCase().includes(inputValue.toLowerCase()));
    //                 }
    //                 resolve(listData)
    //             })
    //         }
    //     })
    // } else {

    // }
  });
export const promiseCarriersByUuid = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/carriers/v2/search/" + inputValue,
      (data) => {
        data.map((d) => {
          d.label = d.name;
          d.value = d.uuid;
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseDriversByPhonePrefix = (cc, dispatchUuid) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/drivers/search/phone/" +
      inputValue +
      "?countryCode=" +
      encodeURIComponent(cc) +
      "&dispatchUuid=" +
      encodeURIComponent(dispatchUuid),
      (data) => {
        data.map((d) => {
          d.value = d.cell;
          d.label =
            d.cell +
            " - " +
            d.firstname +
            " " +
            d.lastname +
            (d.cdl
              ? " [" + d.cdl + (d.cdlState ? ", " + d.cdlState : "") + "]"
              : "");
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseZoneByPrefix = (locationUuid) => (inputValue) => {
  if (locationUuid == "") {
    return new Promise((resolve) => resolve([]));
  }
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/spot/v2/location/" +
      locationUuid +
      "/zone/" +
      (inputValue ? "?zoneQuery=" + encodeURIComponent(inputValue) : ""),
      (data) => {
        let listData = data.content.map((d) => {
          let li = {
            label: d.zone,
            value: d.zone,
            ...d,
          };
          return li;
        });
        resolve(listData);
      }
    );
  });
};
export const promiseTractorByIdPrefixAndTagEZTrackAssets = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/tractors/search/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label =
            d.assetId +
            " - Carrier: " +
            d.carrier.name +
            (d.licenseState ? " - State: " + d.licenseState : "") +
            (d.devices && d.devices.length > 0 ? " (EZTrack)" : "");
          return d;
        });
        data.sort((a, b) => {
          if (a.devices.length > 0 && b.devices.length > 0) {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          } else if (a.devices.length > 0 && b.devices.length === 0) {
            return -1;
          } else if (a.devices.length === 0 && b.devices.length > 0) {
            return 1;
          }
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        resolve(data);
      }
    );
  });

export const promiseTractorByIdPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/tractors/search/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label =
            d.assetId +
            " - Carrier: " +
            d.carrier.name +
            (d.licenseState ? " - State: " + d.licenseState : "");
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseTrailerByIdPrefixAndTagEZTrackAssets = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/trailer/search/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label = d.assetId;
          if (d.carrier) {
            d.label = d.label + " - Carrier: " + d.carrier.name;
          }
          if (d.licenseState) {
            d.label = d.label + " - State: " + d.licenseState;
          }
          if (d.devices && d.devices.length > 0) {
            d.label = d.label + " (EZTrack)";
          }
          return d;
        });
        data.sort((a, b) => {
          if (a.devices.length > 0 && b.devices.length > 0) {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          } else if (a.devices.length > 0 && b.devices.length === 0) {
            return -1;
          } else if (a.devices.length === 0 && b.devices.length > 0) {
            return 1;
          }
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        resolve(data);
      }
    );
  });

export const promiseTrailerByIdPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/trailer/search/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label = d.assetId;
          if (d.carrier) {
            d.label = d.label + " - Carrier: " + d.carrier.name;
          }
          if (d.licenseState) {
            d.label = d.label + " - State: " + d.licenseState;
          }
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseTrailerByIdPrefixWithFilter =
  (trailerNumber = "") =>
    (inputValue) =>
      new Promise((resolve) => {
        httpGet(
          Config.primaryApiServer +
          "/api/assets/trailer/search/assetid/" +
          inputValue,
          (data) => {
            const newMap = [];
            data.map((d) => {
              d.value = d.assetId;
              d.label =
                d.assetId +
                " - Carrier: " +
                d.carrier.name +
                (d.licenseState ? " - State: " + d.licenseState : "");
              if (d.assetId !== trailerNumber) newMap.push(d);
              return d;
            });
            resolve(newMap);
          }
        );
      });

export const promiseTrailerByCarrierAndIdPrefixWithFilter =
  (carrier, trailerNumber = "") =>
    (inputValue) =>
      new Promise((resolve) => {
        httpGet(
          Config.primaryApiServer +
          "/api/assets/trailers/search/carrier/" +
          encodeURIComponent(carrier) +
          "/assetid/" +
          inputValue,
          (data) => {
            const newMap = [];
            data.map((d) => {
              d.value = d.assetId;
              d.label =
                d.assetId +
                " - Carrier: " +
                d.carrier.name +
                (d.licenseState ? " - State: " + d.licenseState : "");
              if (d.assetId !== trailerNumber) newMap.push(d);
              return d;
            });
            resolve(newMap);
          }
        );
      });

export const promiseReferenceNumbersByTypeAndSearchTerm =
  (referenceNumberType) => (searchTerm) =>
    new Promise((resolve) => {
      httpGet(
        Config.primaryApiServer +
        "/api/shipment-reference/search/" +
        encodeURIComponent(referenceNumberType) +
        (searchTerm ? "/" + encodeURIComponent(searchTerm) : ""),
        (data) => {
          data = data[0].uuid
            ? data.map((d) => {
              d.value = d.value;
              d.label = d.value;
              return d;
            })
            : undefined;
          resolve(data);
        }
      );
    });

export const promiseTrailerByCarrierAndIdPrefix = (carrier) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets/trailers/search/carrier/" +
      encodeURIComponent(carrier) +
      "/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label =
            d.assetId +
            " - Carrier: " +
            d.carrier.name +
            (d.licenseState ? " - State: " + d.licenseState : "");
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseTractorByCarrierAndIdPrefix = (carrier) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/assets" +
      "/tractors/search/carrier/" +
      encodeURIComponent(carrier) +
      "/assetid/" +
      inputValue,
      (data) => {
        data.map((d) => {
          d.value = d.assetId;
          d.label =
            d.assetId +
            " - Carrier: " +
            d.carrier.name +
            (d.licenseState ? " - State: " + d.licenseState : "");
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseCompanyByIdentification = (type) => (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/companies/v2/search/" +
      inputValue +
      "?type=" +
      type,
      (res) => {
        let data = res.content;
        data.map((d) => {
          d.value = d.name;
          d.label = d[type] + " - " + d.name;
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseCompanyByPrefix = (inputValue) =>
  new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/companies/v2/search/" + inputValue,
      (res) => {
        let data = res.content;
        data.map((d) => {
          d.value = d.name;
          d.label = d.name;
          return d;
        });
        resolve(data);
      }
    );
  });

export const promiseLocationSearchWithNickname = (check) => (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/locations/userlocations/",
      (data) => {
        let listData = data.map((d) => {
          let label = d.nickname ? d.name + " (" + d.nickname + ")" : d.name;
          d.label = label;
          return d;
        });
        if (check) {
          listData = listData.filter((it) => it.ezCheckInSite === true);
        }
        if (inputValue !== "") {
          listData = listData.filter((it) =>
            it.name.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        resolve(listData);
      }
    );
  });
};

export const promiseLocationSearch = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer + "/api/locations/search/" + inputValue,
      (data) => {
        data = data.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        // let a = [];
        // for (let index = 0; index < 9; index++) {
        //     a.push(data[index]);

        // }

        resolve(data);
      }
    );
  });
};

export const promiseUserLocationSearch = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/userlocations/" +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (res) => {
        let data = res.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });

        resolve(data);
      }
    );
  });
};

export const getLocationsForUserForm = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/alllocation" +
      (inputValue ? "?prefix=" + encodeURIComponent(inputValue) : ""),
      (res) => {
        let data = res.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        // if (inputValue !== "") {
        //     data = data.filter(it => it.name.toLowerCase().includes(inputValue.toLowerCase()));
        // }

        resolve(data);
      }
    );
  });
};
export const promiseAllLocation = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(Config.primaryApiServer + "/api/locations/", (data) => {
      data = data.map((d) => {
        return {
          value: d.uuid,
          label: d.name,
          isDomicile: d.isDomicile,
          name: d.name,
          uuid: d.uuid,
        };
      });
      if (inputValue !== "") {
        data = data.filter((it) =>
          it.name.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
      // let a = [];
      // for (let index = 0; index < 9; index++) {
      //     a.push(data[index]);

      // }

      resolve(data);
    });
  });
};
export const promiseAssetTypeSearch = (location) => (inputValue) => {
  return new Promise((resolve) => {
    let obj = {
      page: 0,
      pageSize: 1000,
      sorted: [],
      filtered: [],
    };
    httpPost(
      Config.primaryApiServer + "/api/eztrack-equipment/v2/search",
      obj,
      (data) => {
        let searchString = inputValue;
        let assetTypeArr = data.content.results;
        let listData = data.content.results;
        listData = listData.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        if (searchString !== "") {
          listData = assetTypeArr.filter((it) =>
            it.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }
        if (location === "location") {
          listData = listData.filter((v) => v.enabled == true);
        }

        resolve(listData);
      }
    );
  });
};
export const promiseAssetTypeSearchByPref = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/eztrack-equipment/v2/search/" +
      (inputValue ? encodeURIComponent(inputValue) : ""),
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        // if (inputValue !== "") {
        //     data = data.filter(it => it.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
        // }
        resolve(data);
      }
    );
  });
};
export const downloadTaskTypesForDropdown = (location) => (inputValue) => {
  return new Promise((resolve) => {
    let obj = {
      page: 0,
      pageSize: 1000,
      sorted: [],
      filtered: [],
    };
    httpPost(
      Config.primaryApiServer + "/api/task-type/v2/search",
      obj,
      (data) => {
        let searchString = inputValue;
        let assetTypeArr = data.content.results;
        let listData = data.content.results;
        listData = listData.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        if (searchString !== "") {
          listData = assetTypeArr.filter((it) =>
            it.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }
        if (location === "location") {
          listData = listData.filter((v) => v.enabled == true);
        }

        resolve(listData);
      }
    );
  });
};

export const promiseCancelReasonSearch = (enabled) => (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/cancel-reason-code/v2/search/" +
      inputValue +
      "?enabled=" +
      enabled,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        resolve(data);
      }
    );
  });
};
export const promiseAllCancelReasonCodes = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/cancel-reason-code/v2/searchEnabled/" +
      (inputValue ? encodeURIComponent(inputValue) : ""),
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.name,
            ...d,
          };
        });
        resolve(data);
      }
    );
  });
};

export const promiseAuthorizedLocationSearch = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/search/authorized/" +
      inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.nickname ? d.nickname : "",
            enableTaskAssigment: d.enableTaskAssigment,
            enableTaskInProcess: d.enableTaskInProcess,
            nickname: d.nickname,
            timezone: d.timezone,
            name: d.name,
            id: d.id,
          };
        });
        resolve(data);
      }
    );
  });
};
export const promiseAuthorizedLocationYardEnableSearch = (inputValue) => {
  return new Promise((resolve) => {
    httpGet(
      Config.primaryApiServer +
      "/api/locations/v2/search/authorized/" +
      inputValue,
      (data) => {
        data = data.content.map((d) => {
          return {
            value: d.uuid,
            label: d.nickname ? d.nickname : "",
            enableTaskAssigment: d.enableTaskAssigment,
            enableTaskInProcess: d.enableTaskInProcess,
            enableYardCheck: d.enableYardCheck,
            nickname: d.nickname,
            timezone: d.timezone,
            name: d.name,
          };
        });
        data = data.filter((it) => it.enableYardCheck === true);
        resolve(data);
      }
    );
  });
};
export const promiseAuthorizedShiftSearch = (inputValue) => {
  return new Promise((resolve) => {
    let data = [];
    let arr = [
      {
        label: "Day",
        value: "Day",
      },
      {
        label: "Mid",
        value: "Mid",
      },
      {
        label: "Night",
        value: "Night",
      },
    ];
    if (inputValue !== "") {
      data = arr.filter((it) =>
        it.label.toLowerCase().includes(inputValue).toLowerCase()
      );
    } else {
      data = arr;
    }

    resolve(data);
  });
};

export function httpPostDownloadFile(
  url = ``,
  data = {},
  filename,
  onsuccess,
  onerror
) {
  // Default options are marked with *
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then((response) => {
      //Email sent
      if (response.status === 202) {
        return null;
      }
      if (response.status !== 200) {
        throw new ResponseCodeError(
          response.statusText,
          response.status,
          null,
          response,
          response.hideToast
        );
      } else {
        return response.blob();
      }
    })
    .then((blob) => {
      if (!blob) {
        if (onsuccess)
          onsuccess({
            emailSent: true,
          });
      } else {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        if (onsuccess)
          onsuccess({
            emailSent: false,
          });
      }
    })
    .catch((error) => {
      if (error && error.authError && error.authError()) {
        document.location.reload(true);
        error = new ResponseCodeError(
          error.response.statusText,
          error.response.status,
          null,
          null,
          error.response.hideToast
        );
        if (onerror) onerror(error);
      } else {
        if (error.response) {
          error.response.json().then((body) => {
            let message = body.message
              ? body.message
              : error.response.statusText
                ? error.response.statusText
                : "Failed request";
            error = new ResponseCodeError(
              message,
              error.response.status,
              body,
              null,
              body.hideToast
            );
            if (onerror) onerror(error);
          });
        } else {
          error = new ResponseCodeError(null, null, null, null, true);
          if (onerror) onerror(error);
        }
      }
    });
}

export function httpPost(url = ``, data = {}, onsuccess, onerror) {
  return invokeHttpMethod("POST", url, data, onsuccess, onerror);
}
export function invokeHttpMethod(
  method,
  url = ``,
  data = {},
  onsuccess,
  onerror
) {
  // Default options are marked with *
  return fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new ResponseCodeError(
          response.statusText,
          response.status,
          null,
          response,
          response.hideToast
        );
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (onsuccess) onsuccess(data);
    })
    .catch((error) => {
      if (error && error.authError && error.authError()) {
        document.location.reload(true);
        error = new ResponseCodeError(
          error.response.statusText,
          error.response.status,
          null,
          null,
          error.response.hideToast
        );
        if (onerror) onerror(error);
      } else {
        if (error.response) {
          error.response.json().then((body) => {
            let message = body.message
              ? body.message
              : error.response.statusText
                ? error.response.statusText
                : "Failed request";
            error = new ResponseCodeError(
              message,
              error.response.status,
              body,
              null,
              body.hideToast
            );
            if (onerror) onerror(error);
          });
        } else {
          error = new ResponseCodeError(null, null, null, null, true);
          if (onerror) onerror(error);
        }
      }
    });
}

export function httpPut(url = ``, data = {}, onsuccess, onerror) {
  return invokeHttpMethod("PUT", url, data, onsuccess, onerror);
}
export function httpPatch(url = ``, data = {}, onsuccess, onerror) {
  return invokeHttpMethod("PATCH", url, data, onsuccess, onerror);
}
export function httpDelete(url = ``, onsuccess, onerror, onautherror) {
  return fetch(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrer: "client", // no-referrer, *client
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new ResponseCodeError(
          "Response from server: " + response.statusText,
          response.status
        );
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (onsuccess) onsuccess(data);
    })
    .catch((error) => {
      if (error && error.authError && error.authError()) {
        if (onautherror) {
          onautherror(error);
        } else {
          document.location.reload(true);
        }
      }
      if (onerror) onerror(error);
    });
}

export function httpGet(url = ``, onsuccess, onerror, onautherror) {
  return fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrer: "client", // no-referrer, *client
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new ResponseCodeError(
          "Response from server: " + response.statusText,
          response.status
        );
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (onsuccess) onsuccess(data);
    })
    .catch((error) => {
      if (error && error.authError && error.authError()) {
        if (onautherror) {
          onautherror(error);
        } else {
          document.location.reload(true);
        }
      }
      if (onerror) onerror(error);
    });
}

export function postFile(
  path,
  file,
  onsuccess,
  onerror,
  form,
  onautherror = null
) {
  form.append("file", file);
  fetch(path, {
    method: "POST",
    body: form,
    credentials: "include",
  })
    .then((response) => {
      if (response.status === 401) {
        throw new ResponseCodeError("Authentication Failure", response.status);
      }
      if (response.status !== 200) {
        return response.json().then((object) => {
          throw new ResponseCodeError(object.message, response.status);
        });
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (onsuccess) onsuccess(data);
    })
    .catch((error) => {
      if (error && error.authError && error.authError()) {
        if (onautherror) {
          onautherror(error);
        } else {
          document.location.reload(true);
        }
      }
      if (onerror) onerror(error);
    });
}

export class ResponseCodeError extends Error {
  constructor(message, statusCode, formError, response, hideToast) {
    super();
    this.message = message;
    this.statusCode = statusCode;
    this.formError = formError;
    this.response = response;
    this.hideToast = hideToast;
  }

  authError() {
    return this.statusCode === 401;
  }
}
